import React from "react";
import { usePageName } from "react-page-name";
import "../projekte/projekte.scss"
import { useEffect, useState } from "react";
import axios from "axios";

const Projekte = () => {

    const [projekte, setProjekte] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Aussstattung
                const projekteRes = await axios.get(`https://api.augschoell.com/wp-json/wp/v2/projekte?per_page=100`);
                if (projekteRes.data) {
                    setProjekte(projekteRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    })

    usePageName("Aktuelles und Projekte | Jakob Augschöll");

    return (
        <div className="projekte">
            <div className="container">
                <h2>Aktuelles und Projekte</h2>
                {isLoading ? <div className="loading" /> : projekte.map(projekte => (
                    <div className="projektContent" key={projekte.id}>
                        <div className="top">
                            <h1>{projekte.title.rendered}</h1>
                            <h3>{projekte.acf.date}</h3>
                        </div>
                        <div className="bottom">
                            <div className="wp-content" dangerouslySetInnerHTML={{ __html: projekte.content.rendered }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Projekte