import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../navbar/navbar.scss"

const Navbar = () => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };

    return (
        <div className="navbar">
            <Link to={"/"}>
                <svg id="Ebene_1" className="svgLogo" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.39 400"><defs></defs><rect x="90" width="50" height="400" /><polygon points="168.39 400 168.39 0 368.39 400 168.39 400" /><rect y="270" width="100" height="130" /></svg>
            </Link>
            <div className="right">
                <div className={isActive ? "openNav active" : "openNav"} onClick={handleClick}>
                    <div className="navLines"></div>
                    <div className="navLines"></div>
                </div>
            </div>


            <div className={isActive ? "nav" : "nav open"}>
                <div className="navigationWrapper">
                    <div className="list">
                        <NavLink to={"/"} onClick={handleClick}>Home</NavLink>
                        <NavLink to={"/projekte"} onClick={handleClick}>Aktuelles und Projekte</NavLink>
                        <NavLink to={"/posts/blasorchester"} onClick={handleClick}>Blasorchester</NavLink>
                        <NavLink to={"/posts/komposition"} onClick={handleClick}>Kompositionen</NavLink>
                        <NavLink to={"/posts/arrangement"} onClick={handleClick}>Arrangements</NavLink>
                        <NavLink to={"/biografie"} onClick={handleClick}>Biografie</NavLink>
                    </div>
                    <div className="contact">

                    </div>
                </div>
            </div>
        </div>
    )
};

export default Navbar