import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import "../biografie/biografie.scss"
import axios from "axios";

const Biografie = () => {

    usePageName(`Biografie | Jakob Augschöll`);

    const [data, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/pages/2/")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="biografie">
            <div className="container">
                {
                    isLoading
                        ? <div className="loading" /> :
                        <div>
                            <h2>{data.title.rendered}</h2>
                            <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                        </div>
                }
            </div>
        </div>
    )
};

export default Biografie