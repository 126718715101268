import React, { useEffect, useState } from "react";
import "./home.scss"
import { usePageName } from "react-page-name";
import Posts from "../../components/posts/posts";
import { Link } from "react-router-dom";
import axios from "axios";

const Home = () => {

    usePageName(`Jakob Augschöll`);

    const [data, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/pages/2/")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="home">
            <div className="header" style={{ background: `url('../../assets/jakob_foto_header.jpg') right center no-repeat` }}>
                <div className="container">
                    <div className="top">
                        <h1>Jakob<br /> Augschöll</h1>
                        <span>Komponist, Arrangeur & Dirigent</span>
                    </div>
                    <div className="bottom">
                        <span>Kompositionen<br />und Arrangements</span>
                        <div className="links">
                            <Link to={"/posts/blasorchester"}>
                                <button>Meine Werke</button>
                            </Link>
                            <Link to={"/biografie"}>
                                <button>Biografie</button>
                            </Link>
                            <Link to={"/projekte"}>
                                <button>Aktuelles und Projekte</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about">
                <div className="container">
                    <h2>Biografie</h2>
                    {
                        isLoading
                            ? <div className="loading" /> :
                            <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    }
                    <Link to={"/biografie"}><button>Zur Biografie</button></Link>
                </div>
            </div>
            <div className="post">
                <div className="container">
                    <h2>Werke</h2>
                    <Posts />
                    <Link to={"/posts/blasorchester"}><button>Alle Werke</button></Link>

                </div>
            </div>
        </div>
    )
};

export default Home