import React from "react";
import { usePageName } from "react-page-name";
import "../news/news.scss"
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

const News = () => {

    const location = useLocation();

    const postsPage = location.pathname.split("/")[2];
    const postsCap = postsPage.charAt(0).toUpperCase() + postsPage.slice(1);

    const [posts, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get(`https://api.augschoell.com/wp-json/wp/v2/kompositionen?per_page=100`);
                const filteredData = res.data.filter(post => post.acf?.type === postsCap);
                setPosts(filteredData);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [postsCap])

    usePageName(`${postsCap} | Jakob Augschöll`);

    return (
        <div className="news">
            <div className="select">
                {
                    postsPage === "blasorchester"
                        ? <Link to={"/posts/blasorchester"} className="selected" >Blasorchester</Link>
                        : <Link to={"/posts/blasorchester"} >Blasorchester</Link>
                }
                {
                    postsPage === "komposition"
                        ? <Link to={"/posts/komposition"} className="selected" >Kompositionen</Link>
                        : <Link to={"/posts/komposition"} >Kompositionen</Link>
                }
                {
                    postsPage === "arrangement"
                        ? <Link to={"/posts/arrangement"} className="selected" >Arrangements</Link>
                        : <Link to={"/posts/arrangement"} >Arrangements</Link>
                }
            </div>
            <div className="container">
                {isLoading ? <div className="loading" /> : posts.map(posts => (
                    <Link className="postContent" to={`/post/${posts.slug}`} key={posts.id}>
                        <div className="inline">
                            <span className="postTyp">{posts.acf.type}</span>
                            <span className="postKategorie">{posts.acf.category}</span>
                            <h3>{posts.title.rendered}</h3>
                            <span className="postDatum">{moment(posts.acf.year).format("YYYY")}</span>
                            <span className="postBg">{posts.acf.type[0]}</span>
                        </div>
                        <div className="content">
                            <div className="raster">
                                {posts.acf.audio
                                    ? <span className="element"><PlayArrowOutlinedIcon /></span>
                                    : <span className="element empty"><PlayArrowOutlinedIcon /></span>
                                }
                                {posts.acf.pdf
                                    ? <span className="element">PDF</span>
                                    : <span className="element empty">PDF</span>
                                }
                                {posts.acf.dur
                                    && <span className="element">{posts.acf.dur}</span>
                                }
                                {posts.acf.grad
                                    && <span className="element">{posts.acf.grad}</span>
                                }
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
};

export default News